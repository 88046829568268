import { ThemeOptions } from '@mui/material';

export const theme: ThemeOptions = {
    components: {
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&.Mui-checked': { color: 'white' },
                },
            },
        },
    }
}
