'use client';
import { apiPlugin, storyblokInit } from '@storyblok/react';

storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    use: [apiPlugin],
});

//@ALERT: Might cause future problems https://stackoverflow.com/questions/77993934/nextjs-mix-server-and-client-components/77997469#77997469

export default function StoryblokProvider() {
    return <></>;
};
